/* You can add global styles to this file, and also import other style files */

@import '~bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
body {
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
}

.swiper-slide {
    text-align: center;
}

.swiper-slide-thumb-active {
    border-bottom: red solid 2px;
}
